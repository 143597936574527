<template>
  <el-dialog
    v-if="LiveCodeDialog.show"
    :title="LiveCodeDialog.title"
    :visible.sync="LiveCodeDialog.show"
    center
    width="500px"
    height="450px"
    top="30vh"
    @close="closeDialog"
  >
    <!-- 课程直播 -->
    <div class="main-wrap">
      <!-- <div class="cont">
        直播二维码：
        <span class="imgCode">
        <img
          src="https://www.wlmqcol.com/files/system/block_picture_1588624630.jpg"
          alt=""
        >
        </span>
      </div> -->
      <div v-if="liveStatus === 1" class="cont">
        <div class="liveUrl">直播地址：<span><a :href="liveUrl" target="_blank" class="go-link">{{ liveUrl }}</a></span></div>
        <div class="platform">直播平台：<span>{{ platform }}</span></div>
        <div v-if="liveCode" class="livecode">直播编码：<span>{{ liveCode }}</span></div>
        <div class="startTime">直播时间：<span>{{ startTime }}</span></div>
      </div>
      <div v-else class="message"><i class="el-icon-warning" style="padding-right:6px;color:#999"></i><span>{{ message }}</span></div>
      <div class="btn-wrap">
        <el-button @click="goBack" type="primary">关闭</el-button>
      </div>
    </div>

  </el-dialog>
</template>
  <script>
import { getCourseLiveCode } from "@/api/product.js";
export default {
  name: "ApplyRefund",
  props: {
        LiveCodeDialog: {
        type: Object,
        default: null,
    },
    },
    data() {
    return {
      courseId: "", //课程Id
      liveStatus: "", //1可播放  0不能播放
      liveUrl: "", //直播url
      platform: "", //直播平台
      liveCode: "", //直播编码
      startTime: "", //播放时间
      message: "", //提示文本
    };
    },
    mounted() {
    this.courseId = this.$route.query.id;
    this.periodId = this.LiveCodeDialog.periodId
    this.getCourseLiveCode(this.periodId);
    },
    methods: {
    // 弹窗二维码
    getCourseLiveCode() {
        getCourseLiveCode({ periodId: this.periodId }).then((res) => {
        console.log(res);
        if (res.data.code === 0) {
            this.liveStatus = res.data.data.liveStatus;
            this.liveUrl = res.data.data.liveUrl;
            this.platform = res.data.data.platform;
            this.liveCode = res.data.data.liveCode;
            this.startTime = res.data.data.startTime;
            this.message = res.data.data.message;
        } else {
            this.$message({
                type: "error",
                message: res.data.message,
            });
            }
        });
    },
    //关闭回调
    closeDialog() {
      this.$emit("dialogClose");
    },
    goBack() {
      this.LiveCodeDialog.show = false;
    },
  },
};
</script>
  <style lang="scss" scoped>
.main-wrap {
  .title {
    margin-bottom: 1.25rem;
  }
  .cont {
    text-align: left;
    margin-bottom:30px;
    color: #999;
    .imgCode{
        display: flex;
    }
    img{
        display: flex;
        margin-left:80px;
    }
    span{
      color:#333;
      padding-left: 5px;
    }
  }
  .liveUrl {
    color: #666;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    a:link, a:visited{
      color:#409EFF;
    }
    a:hover, a:active{
      color:#409EFF;
      text-decoration: none;
    }
  }
  .platform {
    color: #666;
    text-align: left;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .livecode {
    text-align: left;
    font-size: 16px;
    margin-bottom: 20px;
    color: #666;
  }
  .startTime {
    text-align: left;
    font-size: 16px;
    margin-bottom: 20px;
    color: #666;
  }
  .message {
    text-align: left;
    font-size: 16px;
    margin-bottom: 20px;
    text{
      color: #333;

    }
  }
  .btn-wrap {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
  }
}
</style>